/* eslint-disable import/no-unassigned-import */
/** *************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/** *************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import 'core-js/es6/symbol';
// import 'core-js/es6/object';
// import 'core-js/es6/function';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/number';
// import 'core-js/es6/math';
// import 'core-js/es6/string';
// import 'core-js/es6/date';
// import 'core-js/es6/array';
// import 'core-js/es6/regexp';
// import 'core-js/es6/map';
// import 'core-js/es6/weak-map';
// import 'core-js/es6/set';

/** Evergreen browsers require these. **/
import 'core-js/es7/reflect';
import 'core-js/es7/array';

// ZONEJS events

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags.ts';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

(window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
// (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

(window as any).__zone_symbol__UNPATCHED_EVENTS = [
  'online',
  'offline',
  'scroll',
  'mouseenter',
  'mouseleave',
  'mousemove',
  'mouseout',
  'mouseover',
  'mousewheel',
  'requestAnimationFrame',
];

/*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
// (window as any).__Zone_enable_cross_context_check = true;

/** *************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.

/** *************************************************************************************************
 * APPLICATION IMPORTS
 */

// Custom Elements polyfill. Required for browsers that do not natively support Custom Elements.
import '@webcomponents/custom-elements';
// Custom Element ES5 shim. Required for browsers that natively support Custom Elements, but do not
// support ES2015 modules.
// NOTE: Chrome, Firefox and Safari should not need this, because they added support for ES2015
//       modules before Custom Elements. It is still required for some other (less common) browsers:
//       - UC browser for android 11.8 (~3.5% global usage)
//       - Samsung browser 5.0-8.1 (~0.43% global usage)
//       - Opera 41-47 (~0.02% global usage)
import '@webcomponents/custom-elements/src/native-shim';

import 'details-polyfill';

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';

// INTERSECTION OBSERVER
import 'intersection-observer/intersection-observer.js';

// navigator.beacon
import 'navigator.sendbeacon';

// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
if (typeof (String as any).prototype.padStart !== 'function') {
  // eslint-disable-next-line no-extend-native
  String.prototype.padStart = function padStart(targetLength: number, padString: string): string {
    let _targetLength: number = targetLength;
    let _padString: string = padString;

    // eslint-disable-next-line no-bitwise
    _targetLength = _targetLength >> 0;
    _padString = String(typeof _padString !== 'undefined' ? _padString : ' ');

    if (this.length > _targetLength) {
      return String(this);
    }

    _targetLength = _targetLength - this.length;

    if (_targetLength > _padString.length) {
      _padString += _padString.repeat(_targetLength / _padString.length);
    }

    return _padString.slice(0, _targetLength) + String(this);
  };
}

// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest

if (typeof Element.prototype.closest !== 'function') {
  Element.prototype.closest = function closest(selector: any): any {
    // eslint-disable-next-line consistent-this
    let element = this;

    while (element && element.nodeType === 1) {
      if (element.matches(selector)) {
        return element;
      }

      element = element.parentNode;
    }

    return null;
  };
}

// polyfill for IE
(function (window: Window): boolean {
  try {
    // eslint-disable-next-line no-new
    new (window as any).MouseEvent('test', {});
    return false; // No need to polyfill
  } catch (e) {
    // Need to polyfill - fall through
  }

  // Polyfills DOM4 MouseEvent

  // eslint-disable-next-line func-style
  const MouseEvent = function (eventType: string, params: any): MouseEvent {
    const _params: any = params || { bubbles: false, cancelable: false };
    const mouseEvent = document.createEvent('MouseEvent');
    mouseEvent.initMouseEvent(
      eventType,
      _params.bubbles,
      _params.cancelable,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null,
    );

    return mouseEvent;
  };

  MouseEvent.prototype = Event.prototype;

  (window as any).MouseEvent = MouseEvent;
  return undefined;
})(window);

Number.isInteger =
  Number.isInteger ||
  function (value: any): boolean {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  };
